.navbar {
	width: 100%;
	padding-top: 50px;
	animation: fadeInDown .5s ease-in both;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	transition: .3s ease-out;

	@keyframes fadeInDown {
		from {
			opacity: 0;
			transform: translate3d(0, -20%, 0);
		}
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	@media (max-width: 1255px) {
		padding-top: 40px;
	}

	@media (max-width: 999px) {
		animation: none;
	}

	.container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&.scrolled {
		background-color: rgba(#000000, .9);
		padding-top: 0;
		animation: fadeInDown .5s ease-in both;
		border-bottom: 4px solid #D2AA42;

		.navbar__logo {
			max-height: 100px;
			max-width: 100px;
			width: 100%;
			height: 100%;
		}
	}

	&__logo {
		max-width: 200px;
		max-height: 200px;
		width: 100%;
		height: 100%;
		transition: .3s ease-out;

		svg {
			width: 100%;
			height: 100%;
		}

		@media (max-width: 1255px) {
			width: 150px;
			height: 150px;
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		font-size: 20px;
		line-height: 44px;

		&-close {
			display: none;
		}

		@media (max-width: 1255px) {
			font-size: 15px;
			line-height: 30px;
		}

		@media (max-width: 999px) {
			position: fixed;
			top: 0;
			right: -100%;
			width: 100vw;
			height: 100vh;
			padding: 50px;
			background-color: #000000;
			z-index: 100000;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			line-height: 23px;
			font-weight: 700;
			font-family: 'Ubuntu', sans-serif;
			transition: right .5s ease-out;

			&.open {
				right: 0;
			}

			.nav-item {
				margin-left: 0 !important;
				margin-bottom: 10px;
			}

			&-close {
				display: block;
				position: absolute;
				background-color: transparent;
				border: none;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}

			.navbar__moreinfo {
				margin-top: 110px;
				display: flex;
				flex-direction: column;

				&-phone {
					font-size: 24px;
					line-height: 28px;
					margin-bottom: 20px;
				}

				&-call {
					font-family: 'Raleway', sans-serif;
					font-weight: 400;
					font-size: 17px;
					line-height: 26px;
				}

				&-btn {
					font-weight: 500;
					font-size: 15px;
					line-height: 23px;
					padding: 10px 80px;
					border: 1px solid #FFFFFF;
					border-radius: 15px;
					text-align: center;
					color: #FFFFFF;
					background-color: transparent;
					transition: .3s ease-out;
					cursor: pointer;
					margin-top: 20px;

					&:hover {
						background-color: #D2AA42;
						border-color: #D2AA42;
					}
				}

				&-social {
					font-family: 'Raleway', sans-serif;
					font-weight: 400;
					font-size: 17px;
					line-height: 26px;
					display: flex;
					flex-wrap: wrap;
					margin-top: 50px;

					a {
						margin-right: 45px;
					}
				}
			}
		}

		.nav-item {
			cursor: pointer;
			position: relative;
			margin-left: 48px;

			&::before {
				content: '';
				position: absolute;
				height: 2px;
				background-color: #D2AA42;
				left: 50%;
				bottom: 0;
				width: 0;
				transition: width .3s ease-out, left .3s ease-out;
			}

			&:hover::before {
				width: 100%;
				left: 0;
			}

			&.active::before {
				width: 100%;
				left: 0;
			}
		}
	}

	&__moreinfo {
		display: none;

		@media (max-width: 999px) {
			display: block;
		}
	}

	&__toggle {
		display: none;
		border: none;
		background-color: transparent;
		cursor: pointer;

		@media (max-width: 999px) {
			display: block;
		}
	}
}
