@import '../../Slider/slider';

.reviews {
	border-top: 2px #D2AA42 solid;
	border-bottom: 2px #D2AA42 solid;

	.review {
		position: relative;
		height: 100%;
		width: 100%;
		padding-top: 95px;
		display: flex;
		justify-content: center;

		@media (max-width: 1255px) {
			padding-top: 70px;
		}

		@media (max-width: 999px) {
			padding-top: 40px;
		}

		&__avatar {
			border-radius: 100%;
			margin-right: 20px;
			width: 100px;
			height: 100px;

			@media (max-width: 999px) {
				height: 70px;
				width: 70px;
			}

			@media (max-width: 767px) {
				height: 50px;
				width: 50px;
			}

			@media (max-width: 479px) {
				height: 40px;
				width: 40px;
			}
		}

		&__name {
			margin-top: 20px;
			margin-bottom: 20px;
			font-family: 'Ubuntu', sans-serif;
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;

			@media (max-width: 1255px) {
				font-size: 26px;
				line-height: 30px;
			}

			@media (max-width: 999px) {
				font-size: 20px;
				line-height: 23px;
				margin-bottom: 10px;
			}

			@media (max-width: 767px) {
				font-size: 17px;
				line-height: 20px;
				margin-top: 10px;
			}
		}

		&__text {
			width: 540px;
			font-weight: 500;
			font-size: 17px;
			line-height: 23px;

			@media (max-width: 1255px) {
				font-size: 15px;
				line-height: 20px;
				width: 405px;
			}

			@media (max-width: 999px) {
				font-size: 12px;
				line-height: 16px;
				width: 300px;
			}

			@media (max-width: 767px) {
				font-size: 11px;
				line-height: 14px;
				width: 250px;
			}

			@media (max-width: 479px) {
				width: 160px;
			}
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: -100000;
			opacity: .4;
		}
	}
}
