.contacts {
	border-top: 2px #D2AA42 solid;
	border-bottom: 2px #D2AA42 solid;
	overflow-x: hidden;

	.container {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		width: 615px;
		font-family: 'Ubuntu', sans-serif;
		text-transform: uppercase;

		&-title {
			font-weight: 700;
			font-size: 45px;
			line-height: 52px;
			opacity: 0;
			transform: translate3d(-100px, 0, 0);
			transition: transform .5s ease-out, opacity .5s ease-out;

			&.on-screen {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}

			@media (max-width: 1255px) {
				width: 405px;
				font-size: 30px;
				line-height: 34px;
			}

			@media (max-width: 999px) {
				width: 350px;
				font-size: 26px;
				line-height: 30px;
			}

			small {
				font-size: 36px;
				line-height: 41px;

				@media (max-width: 1255px) {
					font-size: 26px;
					line-height: 30px;
				}

				@media (max-width: 999px) {
					font-size: 24px;
					line-height: 28px;
				}
			}

			span {
				font-size: 40px;
				color: #D2AA42;
				line-height: 46px;

				@media (max-width: 1255px) {
					font-size: 30px;
					line-height: 34px;
				}

				@media (max-width: 999px) {
					font-size: 26px;
					line-height: 30px;
				}
			}
		}

		&-text {
			margin: 70px 0;
			font-weight: 500;
			font-size: 22px;
			line-height: 25px;

			@keyframes typing {
				from {
					width: 0
				}
				to {
					width: 100%
				}
			}

			@media (max-width: 1255px) {
				margin: 50px 0;
				font-size: 17px;
				line-height: 20px;
			}

			@media (max-width: 999px) {
				font-size: 14px;
				line-height: 16px;
			}
		}

		&-btn {
			padding: 21px 61px;
			background-color: transparent;
			border: 1px #FFFFFF solid;
			border-radius: 15px;
			color: #D2AA42;
			font-size: 14px;
			line-height: 16px;
			font-family: 'Ubuntu', sans-serif;
			font-weight: 400;
			text-transform: uppercase;
			cursor: pointer;
			display: inline-block;
			opacity: 0;
			transform: translate3d(-100px, 0, 0);
			transition: transform .5s ease-out, opacity .5s ease-out, .35s ease;

			&.on-screen {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}

			&:hover {
				border-color: #D2AA42;
				background-color: #D2AA42;
				color: #000000;
			}

			@media (max-width: 1255px) {
				font-size: 12px;
				line-height: 14px;
				padding: 18px 40px;
			}
		}
	}

	&__image {
		opacity: 0;
		transform: scale(.5);
		transition: transform .5s ease-out, opacity .5s ease-out;

		&.on-screen {
			opacity: 1;
			transform: scale(1);
		}

		@media (max-width: 1255px) {
			height: 330px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}
}
