.services {
	&__cards {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;

		@media (max-width: 1255px) {
			gap: 14px;
		}

		@media (max-width: 999px) {
			gap: 20px;
		}

		@media (max-width: 767px) {
			gap: 30px;
			grid-template-columns: 1fr;
		}
	}

	&__card {
		padding: 30px 20px;
		height: 557px;
		width: 100%;
		border-radius: 15px;
		position: relative;
		overflow: hidden;
		will-change: transform;
		opacity: 0;
		transform: translate3d(0, 100px, 0);
		transition: opacity .5s ease-in, transform .5s ease-in;

		&.on-screen {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}

		@media (max-width: 1255px) {
			padding: 20px;
			height: 440px;
		}

		@media (max-width: 999px) {
			height: 325px;
		}

		@media (max-width: 767px) {
			max-width: 229px;
			height: 325px;
			margin: 0 auto;
		}

		&:hover {
			.services__card {
				&-content {
					transform: translateY(0);
				}

				&-image {
					filter: blur(8px);
					opacity: .75;
				}
			}
		}

		&-content {
			height: 100%;
			transform: translateY(calc(100% - 69px));
			transition: transform .5s ease-out;

			@media (max-width: 1255px) {
				transform: translateY(calc(100% - 62px));
			}

			@media (max-width: 999px) {
				transform: translateY(calc(100% - 49px));
			}
		}

		&-image {
			position: absolute;
			z-index: -1;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			transition: all .5s ease-out;
			object-fit: cover;
		}

		&-title {
			font-family: 'Ubuntu', sans-serif;
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;

			@media (max-width: 999px) {
				font-size: 17px;
				line-height: 20px;
			}
		}

		&-divider {
			display: block;
			height: 1px;
			border: 0;
			border-top: 2px solid #FFFFFF;
			margin: 20px 0;
			padding: 0;

			@media (max-width: 999px) {
				margin: 15px 0;
			}
		}

		&-text {
			font-size: 17px;
			line-height: 22px;
			padding: 30px 20px;

			@media (max-width: 1255px) {
				padding: 15px 0 0 0;
				font-size: 12px;
				line-height: 15px;
			}

			@media (max-width: 999px) {
				font-size: 11px;
				line-height: 13px;
			}

			p {
				margin-bottom: 60px;

				@media (max-width: 1255px) {
					margin-bottom: 40px;
				}

				@media (max-width: 999px) {
					margin-bottom: 20px;
				}
			}
		}
	}
}
