.reviews__slider {
	height: 370px;
	width: 100%;
	position: relative;
	overflow: hidden;
	transform: scale(.05);
	opacity: 0;
	transition: transform .5s ease-out, opacity .5s ease-out;

	@media (max-width: 999px) {
		height: 255px;
	}

	@media (max-width: 767px) {
		height: 235px;
	}

	&.on-screen {
		transform: scale(1);
		opacity: 1;
	}

	&-buttons {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&-container {
		display: flex;
		width: 100%;
		height: 100%;
	}

	&-item {
		flex-shrink: 0;
		width: 100%;
		height: 100%;
	}

	&-button {
		position: absolute;
		bottom: 50%;
		transform: translateY(50%);
		z-index: 100;
		background-color: transparent;
		border: none;
		cursor: pointer;

		&.left {
			left: 0;
		}

		&.right {
			right: 0;
		}
	}

	&-dots {
		position: absolute;
		bottom: 75px;
		left: 50%;
		transform: translate(-50%);
		display: flex;

		@media (max-width: 1255px) {
			bottom: 70px;
		}

		@media (max-width: 999px) {
			bottom: 50px;
		}

		@media (max-width: 767px) {
			bottom: 30px;
		}

		@media (max-width: 479px) {
			bottom: 20px;
		}

		.dot {
			width: 10px;
			height: 10px;
			background-color: rgba(#7D7D7D, .5);
			border-radius: 100%;
			transition: background-color .3s ease-out;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			@media (max-width: 767px) {
				width: 5px;
				height: 5px;
				margin-right: 8px;

				&:last-child {
					margin-right: 0;
				}
			}

			&.active {
				background-color: #D2AA42;
			}
		}
	}
}
