@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&family=Ubuntu:wght@300;400;500;700&display=swap');

@import 'components/sections/Main/main-section';
@import 'components/sections/Services/services-section';
@import 'components/sections/About/about-section';
@import 'components/sections/Reviews/reviews-section';
@import 'components/sections/Contacts/contacts-section';
@import 'components/sections/Application/application-section';
@import 'components/Footer/footer';
@import 'components/Loader/loader';
@import 'components/sections/section';

* {
	padding: 0;
	margin: 0;
	outline: 0;
	box-sizing: border-box;
}

ul, ol {
	list-style-type: none;
}

a {
	text-decoration: none;
	color: inherit;
}

body {
	font-family: 'Raleway', sans-serif;
	background-color: #000000;
	color: #FFFFFF;

	&.disabled {
		overflow: hidden;
	}
}

.container {
	width: 100%;
	margin: 0 auto;

	@media (min-width: 376px) {
		max-width: 348px;
	}

	@media (min-width: 480px) {
		max-width: 440px;
	}

	@media (min-width: 768px) {
		max-width: 728px;
	}

	@media (min-width: 1000px) {
		max-width: 960px;
	}

	@media (min-width: 1256px) {
		max-width: 1216px;
	}

	@media (max-width: 375px) {
		padding: 0 14px;
	}
}
