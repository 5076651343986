.about {
	border-top: 2px #D2AA42 solid;
	border-bottom: 2px #D2AA42 solid;
	position: relative;
	overflow-x: hidden;

	.section__title {
		margin-bottom: 85px;

		@media (max-width: 1255px) {
			margin-bottom: 55px;
		}

		@media (max-width: 999px) {
			margin-bottom: 40px;
		}

		@media (max-width: 767px) {
			margin-bottom: 45px;
		}
	}

	&__text {
		width: 620px;
		padding: 35px 0 35px 35px;
		font-size: 22px;
		border-radius: 15px;
		line-height: 41px;
		position: relative;
		transform: translate(-100%, -200px);
		transition: transform .75s ease-out, opacity .75s ease-out;
		transition-delay: .25s;
		opacity: 0;

		&.on-screen {
			transform: translate(0, 0);
			opacity: 1;

			&::before, &::after {
				transform: translate(0, 0);
			}
		}

		@media (max-width: 1255px) {
			width: 470px;
			font-size: 16px;
			line-height: 30px
		}

		@media (max-width: 999px) {
			width: 305px;
			font-size: 12px;
			line-height: 23px;
			padding: 15px 0 15px 15px;
		}

		@media (max-width: 767px) {
			margin: 0 auto;
		}

		&::before, &::after {
			content: '';
			position: absolute;
			border-radius: 15px;
		}

		&::before {
			background-color: #000000;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			transition-delay: .25s;
		}

		&::after {
			width: 440px;
			height: 450px;
			top: -16px;
			left: -16px;
			background-color: #D2AA42;
			z-index: -2;
			transition-delay: 1s;
			transform: translate(-100%, -200px);
			transition: transform .75s ease-out;

			@media (max-width: 1255px) {
				width: 300px;
				height: 340px;
			}

			@media (max-width: 999px) {
				width: 245px;
				height: 285px;
				top: -10px;
				left: -10px;
			}
		}

		p {
			margin-bottom: 30px;

			@media (max-width: 1255px) {
				margin-bottom: 23px;
			}
		}

		span {
			color: #D2AA42;
		}
	}

	&__image {
		position: absolute;
		right: 0;
		top: 200px;
		z-index: -1;
		transform: translateX(100%);
		transition: transform 1s ease-in-out;

		&.on-screen {
			transform: translateX(0);
		}

		@media (max-width: 1255px) {
			height: 575px;
			top: 170px;
		}

		@media (max-width: 999px) {
			height: 385px;
			top: 160px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}
}
