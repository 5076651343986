.footer {
	background-color: #D2AA42;
	font-family: 'Ubuntu', sans-serif;

	.container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.copyright, .authors {
		position: absolute;
		font-family: 'Ubuntu', sans-serif;
		font-size: 10px;
		line-height: 11px;
		text-transform: uppercase;
		bottom: -54px;

		@media (max-width: 767px) {
			bottom: -40px;
		}
	}

	.copyright {
		left: 0;

		@media (max-width: 767px) {
			bottom: -25px;
		}
	}

	.authors {
		right: 0;
		text-transform: none;

		@media (max-width: 767px) {
			left: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__logo {
		width: 170px;
		height: 170px;
		fill: #FFFFFF;

		@media (max-width: 1255px) {
			width: 130px;
			height: 130px;
		}

		@media (max-width: 767px) {
			display: none;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;

		@media (max-width: 1255px) {
			font-size: 17px;
			line-height: 20px;
		}
	}

	&__getintouch {
		font-size: 12px;
		line-height: 14px;
		align-self: stretch;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;

		@media (max-width: 1255px) {
			font-size: 10px;
			line-height: 11px;
		}
	}

	.getintouch {
		&__title {
			margin-bottom: 10px;

			@media (max-width: 1255px) {
				margin-bottom: 15px;
			}
		}

		&__row {
			display: flex;
			align-items: center;
		}

		&__icon {
			width: 25px;
			height: 25px;
			margin-right: 8px;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		&__location {
			align-items: flex-start;

			&-content {
				padding-top: 5px;

				p {
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 2px;
					}
				}
			}
		}

		&__phone {
			margin-bottom: 4px;

			&-content {
				padding: 13px 0;
				border-bottom: 1px #FFFFFF solid;
			}
		}

		&__email {
			margin-bottom: 10px;

			&-content {
				padding: 13px 0;
				border-bottom: 1px #FFFFFF solid;
			}
		}

		&__socialmedia {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: auto;
			margin-bottom: 0;
			padding-bottom: 20px;

			&-icon {
				width: 34px;
				height: 34px;
				cursor: pointer;
				position: relative;

				svg {
					width: 10%;
					height: 100%;
				}

				&::before, &::after {
					content: '';
					position: absolute;
					left: 50%;
					transform: translate(-50%, 3px);
					visibility: hidden;
					transition: transform .5s ease-out;
				}

				&::before {
					top: -10px;
					border: 5px transparent solid;
					border-top: 5px #FFFFFF solid;
				}

				&::after {
					content: attr(data-label);
					top: -34px;
					padding: 5px 10px;
					border-radius: 4px;
					color: #084B61;
					font-size: 16px;
					background-color: #FFFFFF;
					text-transform: capitalize;
				}

				&:hover {
					&::before, &::after {
						transform: translate(-50%, 0);
						visibility: visible;
					}
				}

				@media (max-width: 1255px) {
					width: 27px;
					height: 27px;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	&__quicklinks {
		display: flex;
		flex-direction: column;

		@media (max-width: 999px) {
			display: none;
		}
	}

	.quicklinks {
		&__title {
			text-align: center;
			margin-bottom: 30px;
		}

		&__btn {
			font-family: 'Ubuntu', sans-serif;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			text-transform: capitalize;
			background-color: transparent;
			border: 1px #FFFFFF solid;
			border-radius: 15px;
			color: #FFFFFF;
			padding: 22px 78px;
			cursor: pointer;
			transition: .3s;
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			&:hover {
				background-color: #FFFFFF;
				color: #D2AA42;
			}

			@media (max-width: 1255px) {
				font-size: 10px;
				line-height: 11px;
				padding: 16px 35px;
			}
		}
	}

	&__hours {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.hours {
		&__days {
			text-transform: uppercase;
			font-weight: 300;
			font-size: 14px;
			line-height: 21px;

			@media (max-width: 1255px) {
				font-size: 11px;
				line-height: 16px;
			}
		}

		&__day {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&.active {
				color: #084B61;
				font-weight: 500;
			}

			b {
				font-weight: 500;
			}
		}

		&__subtitle {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			padding-bottom: 15px;

			@media (max-width: 1255px) {
				font-size: 10px;
				line-height: 13px;
			}
		}
	}
}
