@import '../../Form/form';

.application {
	border-top: 2px #D2AA42 solid;
	border-bottom: 2px #D2AA42 solid;

	.section__title {
		margin-bottom: 30px;

		@media (max-width: 999px) {
			margin-bottom: 20px;
		}
	}

	&__title {
		font-family: 'Ubuntu', sans-serif;
		font-weight: 500;
		font-size: 32px;
		line-height: 37px;
		color: #D2AA42;
		text-transform: capitalize;
		margin-bottom: 30px;
		margin-top: 20px;
		text-align: center;
		opacity: 0;
		transform: translate(0, 50px) scale(.75);
		transition: opacity .5s ease-in .35s, transform .5s ease-in .35s;

		&.on-screen {
			opacity: 1;
			transform: translate(0, 0) scale(1);
		}

		@media (max-width: 1255px) {
			font-size: 26px;
			line-height: 30px;
		}

		@media (max-width: 999px) {
			font-size: 22px;
			line-height: 25px;
		}
	}

	&__subtitle {
		font-family: 'Ubuntu', sans-serif;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		opacity: 0;
		transform: translate(0, 50px) scale(.75);
		transition: opacity .5s ease-in .5s, transform .5s ease-in .5s;

		&.on-screen {
			opacity: 1;
			transform: translate(0, 0) scale(1);
		}

		@media (max-width: 1255px) {
			font-size: 20px;
			line-height: 23px;
		}

		@media (max-width: 999px) {
			font-size: 14px;
			line-height: 16px;
		}

		@media (max-width: 767px) {
			font-size: 12px;
			line-height: 14px;
		}

		a {
			color: #D2AA42;
			border-bottom: 2px #D2AA42 solid;
		}
	}
}
