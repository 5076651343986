.section {
	padding: 100px 0;

	@media (max-width: 1255px) {
		padding: 90px 0;
	}

	@media (max-width: 999px) {
		padding: 70px 0;
	}

	@media (max-width: 767px) {
		padding: 50px 0;
	}

	&__title {
		text-transform: uppercase;
		font-family: 'Ubuntu', sans-serif;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 70px;
		opacity: 0;
		transform: translate(0, 50px) scale(.75);
		transition: opacity .5s ease-in, transform .5s ease-in;

		&.on-screen {
			opacity: 1;
			transform: translate(0, 0) scale(1);
		}

		&-text {
			font-size: 36px;
			line-height: 41px;
			margin-right: 25px;

			@media (max-width: 1255px) {
				font-size: 32px;
				line-height: 37px;
			}

			@media (max-width: 999px) {
				font-size: 26px;
				line-height: 30px;
			}

			@media (max-width: 767px) {
				font-size: 22px;
				line-height: 25px;
			}
		}

		&-icon {
			width: 38px;
			height: 38px;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}
