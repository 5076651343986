@import '../../Navbar/navbar';

.main {
	border-bottom: 2px #D2AA42 solid;

	.container {
		position: relative;
		height: 100%;
		padding-top: 90px;

		@media (max-width: 1255px), (max-height: 500px) {
			padding-top: 40px;
		}

		@media (max-width: 999px), (max-height: 750px) {
			padding-top: 50px;
		}
	}

	&__background-video {
		width: 100%;
		object-fit: cover;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
		pointer-events: none;
		opacity: .5;
		transition: opacity .5s linear;
	}

	&__offer {
		font-family: 'Ubuntu', sans-serif;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 340px;

		@media (max-width: 1255px), (max-height: 500px) {
			margin-top: 230px;
		}

		@media (max-width: 999px), (max-height: 750px) {
			margin-top: 245px;
		}

		&-title {
			font-size: 42px;
			line-height: 48px;
			text-transform: uppercase;
			animation: fadeInRight .5s ease-in both;
			animation-delay: .3s;
			margin-bottom: 40px;

			@keyframes fadeInRight {
				from {
					opacity: 0;
					transform: translate3d(-100px, 0, 0);
				}
				to {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
			}

			@media (max-width: 1255px) {
				font-size: 36px;
				line-height: 41px;
				margin-bottom: 35px;
			}

			@media (max-width: 999px), (max-height: 750px) {
				font-size: 32px;
				line-height: 37px;
				margin-bottom: 30px;
			}

			@media (max-width: 767px), (max-height: 500px) {
				font-size: 28px;
				line-height: 32px;
				margin-bottom: 25px;
			}
		}

		&-text {
			font-size: 28px;
			line-height: 32px;
			animation: fadeInRight .5s ease-in both;
			animation-delay: .5s;
			margin-bottom: 40px;

			@media (max-width: 1255px) {
				font-size: 20px;
				line-height: 27px;
				margin-bottom: 35px;
			}

			@media (max-width: 999px), (max-height: 750px) {
				font-size: 17px;
				line-height: 23px;
				margin-bottom: 30px;
			}

			@media (max-width: 767px) {
				margin-bottom: 25px;
			}
		}

		&-btn {
			background-color: transparent;
			border: 1px solid #FFFFFF;
			border-radius: 15px;
			color: #D2AA42;
			font-size: 17px;
			line-height: 20px;
			padding: 20px 120px;
			font-family: 'Raleway', sans-serif;
			cursor: pointer;
			display: block;
			animation: fadeInRight .5s ease-in both;
			animation-delay: .7s;
			position: relative;
			transition: .75s ease;

			&:hover {
				background-color: #D2AA42;
				border-color: #D2AA42;
				color: #000000;

				&::before {
					background-color: #D2AA42;
					top: 60px;

					@media (max-width: 1255px) {
						top: 50px;
					}

					@media (max-width: 999px), (max-height: 750px) {
						top: 45px;
					}

					@media (max-width: 676px), (max-height: 500px) {
						top: 40px;
					}
				}
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 50px;
				background: transparent;
				height: 50%;
				width: 100%;
				transform: perspective(1em) rotateX(40deg) scale(1, .35);
				filter: blur(8px);
				transition: .75s ease;
			}

			@media (max-width: 1255px) {
				font-size: 12px;
				line-height: 14px;
				padding: 18px 95px;
			}

			@media (max-width: 999px), (max-height: 750px) {
				padding: 15px 80px;
			}
		}
	}

	&__scroll-down {
		position: absolute;
		font-size: 20px;
		line-height: 22px;
		right: 0;
		bottom: 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		background-color: transparent;
		border: none;
		color: #FFFFFF;
		font-family: 'Raleway', sans-serif;
		font-weight: 600;
		animation: bounce 2s ease infinite;

		@keyframes bounce {
			0% {
				transform: translateY(0);
			}
			50% {
				transform: translateY(-20px);
			}
			100% {
				transform: translateY(0);
			}
		}

		&:hover {
			animation-play-state: paused;
		}

		@media (max-width: 1255px), (max-height: 750px) {
			bottom: 90px;
			font-size: 15px;
			line-height: 18px;
		}

		@media (max-width: 375px) {
			right: 14px;
		}

		&-icon {
			margin-top: 10px;
		}
	}
}
