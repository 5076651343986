.loader {
	display: none;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100000;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	background-color: #000000;

	&__line {
		display: none;
		width: 15px;
		height: 40px;
		background-color: #D2AA42;
		border-radius: 15px;
		margin: 5px;

		&:first-child {
			animation: loading .7s .6s infinite;
		}

		&:nth-child(2) {
			animation: loading .7s .3s infinite;
		}

		&:nth-child(3) {
			animation: loading .7s .6s infinite;
		}

		&:nth-child(4) {
			animation: loading .7s .3s infinite;
		}

		&:last-child {
			animation: loading .7s .6s infinite;
		}

		@keyframes loading {
			0% {
				height: 40px;
				width: 15px;
				margin: 5px;
			}
			50% {
				height: 100px;
				width: 10px;
				margin: 7.5px;
			}
			100% {
				height: 40px;
				width: 15px;
				margin: 5px;
			}
		}
	}
}
