.dropdown {
	cursor: pointer;
	text-align: left;

	&:is([aria-expanded=true]) {
		& ~ .dropdown__list {
			max-height: 232px;
			border: 1px solid #FFFFFF;
			transition: max-height .3s ease-out;
		}

		& ~ .application__form-icon {
			transform: rotate(-180deg);
		}
	}

	&__list {
		width: 100%;
		max-height: 0;
		background-color: #000000;
		margin-top: 5px;
		z-index: 100;
		position: absolute;
		left: 0;
		overflow: scroll;
		border-radius: 15px;

		&-item {
			cursor: pointer;
			font-family: 'Ubuntu', sans-serif;
			font-size: 17px;
			font-weight: 500;
			line-height: 20px;
			padding: 13px 30px;
			transition: .1s;

			@media (max-width: 999px) {
				font-size: 12px;
				line-height: 14px;
				padding: 16px 30px;
			}

			&:hover, &:is([aria-selected=true]) {
				background-color: #2F2F2F;
			}

			&:is([aria-selected=true]) {
				color: #D2AA42;
			}
		}
	}

	& ~ .application__form-icon {
		transition: transform .3s ease-out;
	}
}
