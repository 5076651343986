@import '../Dropdown/dropdown';

.application__form {
	width: 100%;
	margin-top: 50px;

	@media (max-width: 767px) {
		margin-top: 30px;
	}

	&-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 30px 20px;
		width: 100%;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}

	&-item {
		position: relative;
	}

	&-input {
		width: 100%;
		font-family: 'Ubuntu', sans-serif;
		font-size: 17px;
		font-weight: 300;
		line-height: 20px;
		background-color: transparent;
		border: 1px #FFFFFF solid;
		padding: 13px 30px;
		border-radius: 15px;
		color: #D2AA42;
		transition: .1s linear;

		@media (max-width: 999px) {
			font-size: 12px;
			line-height: 14px;
			padding: 16px 30px;
		}

		&:-webkit-autofill {
			&, &:hover, &:focus {
				box-shadow: inset 0 0 0 1px #000000, inset 0 0 0 100px #000000;
				border: 1px #D2AA42 solid;
				-webkit-text-fill-color: #D2AA42;
				transition: background-color 0s ease-in-out 50000s;
			}
		}

		&:hover {
			border-color: #D2AA42;

			& ~ .application__form {
				&-label {
					color: #D2AA42;
				}

				&-icon {
					svg {
						fill: #D2AA42;
					}
				}
			}
		}

		&[type='file'] {
			cursor: pointer;
			padding-left: 25px;

			@media (max-width: 999px) {
				padding-left: 25px;
			}

			&::file-selector-button {
				background-color: #000000;
				border: none;
				outline: none;
				width: 0;
				height: 0;
				padding: 0;
			}
		}

		&:focus,
		&:is([aria-expanded=true]),
		&.not-empty {
			border-color: #D2AA42;

			& ~ .application__form {
				&-label {
					font-size: 13px;
					line-height: 15px;
					color: #D2AA42;
					font-weight: 500;
					top: -10px;
				}

				&-icon {
					svg {
						fill: #D2AA42;
					}
				}
			}
		}

		&.invalid {
			border-color: crimson;
			color: crimson;

			& ~ .application__form {
				&-label {
					color: crimson;
				}

				&-icon {
					svg {
						fill: crimson;
					}
				}
			}
		}
	}

	&-label {
		position: absolute;
		left: 29px;
		top: 12px;
		font-family: 'Ubuntu', sans-serif;
		font-size: 17px;
		font-weight: 300;
		line-height: 20px;
		background-color: #000000;
		padding: 2px;
		pointer-events: none;
		color: rgba(#FFFFFF, .7);
		transition: top .1s ease-out, font-size .1s ease-out, color .1s linear;

		@media (max-width: 999px) {
			font-size: 12px;
			line-height: 14px;
			top: 15px;
		}
	}

	&-error {
		top: -35px;
		right: 10%;
		padding: 5px 10px;
		border-radius: 4px;
		font-size: 12px;
		background-color: crimson;
		color: #FFFFFF;
		animation: error 2s ease infinite;

		@keyframes error {
			0% {
				transform: translateY(0);
			}
			50% {
				transform: translateY(-2px);
			}
			100% {
				transform: translateY(0);
			}
		}

		&, &::before {
			position: absolute;
			transition: transform .5s ease-out;
		}

		&::before {
			content: '';
			right: 20px;
			bottom: -10px;
			border: 5px transparent solid;
			border-top: 5px crimson solid;
		}
	}

	&-icon {
		width: 18px;
		height: 18px;
		position: absolute;
		top: 15px;
		right: 32px;
		pointer-events: none;

		@media (max-width: 999px) {
			width: 18px;
			height: 18px;
			top: 14px;
			padding: 2px;
		}

		svg {
			width: 100%;
			height: 100%;
			fill: rgba(#FFFFFF, .7);
			transition: .1s linear;
		}
	}

	&-agreement {
		font-size: 11px;
		opacity: .7;
		display: block;
		max-width: 400px;
		text-align: center;
		margin: 15px auto 0 auto;
		font-family: 'Ubuntu', sans-serif;
		font-weight: 300;

		a {
			text-decoration: underline;
		}
	}

	&-button {
		font-family: 'Ubuntu', sans-serif;
		font-size: 17px;
		line-height: 20px;
		background-color: #D2AA42;
		border-radius: 15px;
		border: none;
		margin: 15px auto 30px auto;
		cursor: pointer;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		transition: .25s ease;
		width: 210px;
		height: 50px;
		position: relative;
		overflow: hidden;

		&:not(.loading):hover {
			.icon {
				transform: translate(7px);
			}
		}

		&:disabled {
			opacity: .75;
			cursor: not-allowed;

			&:hover .icon {
				transform: none;
			}
		}

		&.loading {
			width: 50px;
			border-radius: 50%;

			.icon {
				transform: rotate(-90deg);
				padding-bottom: 4px;
				padding-left: 3px;
			}

			.text {
				transform: translate(-140px);
			}

			.line {
				opacity: 1;
			}
		}

		.icon {
			position: absolute;
			left: 0;
			pointer-events: none;
			z-index: 10;
			background-color: inherit;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			transition: .25s ease;

			svg {
				width: 20px;
				height: 20px;
			}
		}

		.text {
			width: 130px;
			display: block;
			pointer-events: none;
			transition: .25s ease;
			position: absolute;
			left: 60px;
		}

		.line {
			position: absolute;
			width: 50px;
			height: 50px;
			z-index: 100;
			border-radius: 50%;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			opacity: 0;
			transition: .25s ease;

			&::before, &::after {
				content: '';
				width: 34px;
				height: 34px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-left: 3px solid #FFFFFF;
				animation: sending infinite .5s linear;
				position: absolute;

				@keyframes sending {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}

			&::before {
				opacity: .3;
			}

			&::after {
				animation-delay: .08s;
			}
		}
	}
}
